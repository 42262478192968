import tw, { styled } from 'twin.macro';
import { Footer } from '../../components/footer';
import Layout from '../../components/Layout';
import { Navigation } from '../../components/Navigation';

const Container = styled.div`
  ${tw`container w-11/12 md:w-3/4 mx-auto mt-10 py-2 px-3 pr-2 md:py-12 md:px-14 md:pr-12 lg:py-20 lg:px-24 lg:pr-20 h-3/4 bg-gray-100 rounded-xl overflow-scroll overflow-x-hidden`}
`;

function GeneralRisk() {
  return (
    <Layout tw="font-body flex flex-col overflow-x-hidden md:overflow-x-visible">
      <Navigation />
      <h2 tw="flex self-center mt-32 text-black text-center text-3xl md:text-6xl font-bold">
        General Risk Disclaimer
      </h2>
      <Container className="example">
        <p tw="text-sm md:text-base xl:text-lg">
          <h1 tw="font-semibold pb-4 text-xl uppercase">
            DISCLAIMER (RISK WARNING)
          </h1>
          <p tw="underline">
            <strong>I. General information about the risk</strong>
          </p>
          <p tw="pb-2 text-sm">
            Bidda sp. z o.o. is the issuer of BIDDA tokens that have
            characteristics close to virtual currencies under the Directive (EU)
            2018/843 of the European Parliament and of the Council of 30 May
            2018 amending Directive (EU) 2015/849 on the prevention of the use
            of the financial system for the purposes of money laundering of
            terrorist financing, and amending Directives (Official Journal of
            the European Union, L 156/43).
          </p>
          <p tw="pb-2 text-sm">
            It means that the BIDDA token is a digital representation of value
            that is not issued or guaranteed by a central bank or a public
            authority, is not necessarily attached to a legally established
            currency and does not possess a legal status of currency or money,
            but is accepted by natural or legal persons as a means of exchange
            and which can be transferred, stored and traded electronically;
          </p>
          <p tw="pb-2 text-sm">
            Bidda sp.z o.o. cannot guarantee the value of the BIDDA token or the
            possibility of its practical use, in particular to pay for goods or
            services with it.
          </p>
          <p tw="pb-2 text-sm">
            The potential use of BIDDA tokens is based on assumptions about the
            future, which is associated with the risk of unforeseen events and,
            as a consequence, there is the possibility of loss of value of BIDDA
            tokens or the inability to use them.
          </p>

          <p tw="pb-4 text-sm">
            Bidda sp.z o.o.declares that:
            <br /> 1) BIDDA tokens may lose their value in part or in full;
            <br />
            2) BIDDA tokens may not always be transferable;
            <br />
            3) BIDDA tokens may not be liquid;
            <br />
            4) BIDDA tokens tokens may not be exchangeable against the good or
            service promised in White Paper, especially in case of failure or
            discontinuation of the project.
          </p>
          <p tw="underline">
            <strong>II. The detailed description of the risk factors</strong>
          </p>
          <p tw="pb-2 text-sm">
            Each investment carries the risk of losing all or part of the
            invested funds. Taking each investment decision is connected with
            the need to assess the impact of risk on the result of the
            investment.
          </p>
          <p tw="pb-4 text-sm">
            The most significant aspects of the risk related to investing in
            BIDDA tokens are listed below.
          </p>
          <p tw="pb-4 text-sm">
            1. <strong>Exchange rate volatility risk (market risk)</strong> -
            there is a risk that the BIDDA token price will change to the
            investor's disadvantage. Tokens are particularly volatile. Single
            transactions can significantly affect their value. The exchange rate
            of tokens may be influenced by macroeconomic indicators
            characteristic of most instruments, i.e. interest rates,
            unemployment rate, economic growth, inflation level, political
            situation. The exchange rate of tokens is also significantly
            influenced by events closely related to the virtual currencies
            market, e.g. hacking attacks weakening the trust in entities
            providing services related to trading in virtual currencies.
          </p>
          <p tw="pb-4 text-sm">
            2. <strong>Regulatory risk</strong> - a change in the law, as well
            as the adoption of an official position by competent supervisory
            authorities on the legal qualification of virtual currencies, may
            directly or indirectly affect the economic situation of investors,
            and thus the value of the BIDDA token.
          </p>
          <p tw="pb-4 text-sm">
            3. <strong>Technological risk</strong> - according to the current
            state of science, it is nearly impossible to break the blockchain
            register, however, entities providing services related to trading
            virtual currencies are exposed to hacking attacks, which may result
            in the loss of all or part of BIDDA tokens. Repeated cyber attacks
            may also lead to loss trust in all technology and, consequently, the
            complete loss of value of virtual currencies
          </p>
          <p tw="pb-4 text-sm">
            4. <strong>Liquidity risk</strong> - there is a risk that the BIDDA
            token cannot be sold without adversely affecting its price. There
            may also be a situation where the sale of BIDDA tokens will not be
            possible at all.
          </p>
          <p tw="pb-4 text-sm">
            5. <strong>Risk of lack of guarantee</strong> - investing in BIDDA
            tokens is structurally different from a bank deposit and is not
            covered by any benefit guarantee system. In particular, an
            investment in BIDDA tokens is associated with the risk of losing all
            or part of the invested funds.
          </p>
          <p tw="pb-2 text-sm">
            6. <strong>Operational risk</strong> – Bidda sp. z o.o. undertakes
            to adhere to the highest professional standards, to keep due
            dilligence and to do its utmost to achieve the goals specified in
            this White Paper. However, Bidda sp. z o.o. can not guarantee
            achieaving these goals. Therefore there is a risk of possibility of
            using inappropriate or defective systems, including human errors,
            technical failures and external events that may affect Bidda sp. z
            o.o. operations, and thus cause failure or discontinuation of the
            project.
          </p>
          <p tw="pb-2 text-sm">
            The types of risks associated with investing in BIDDA tokens
            described in this document may exist alone, however, in some cases
            they may also accumulate due to the correlations between them. The
            investor should be aware of the possibility of a situation in which,
            as a result of a cause-and-effect sequence, several types of risk
            will materialize.
          </p>
          <p tw="pb-2 text-sm">
            Bidda sp. z o.o. recommends that, before purchasing BIDDA tokens,
            the investor should get acquainted with the specificity of virtual
            currencies and assess whether the investment is appropriate for him,
            taking into account the risk level tolerated by him, the expected
            rate of return and his knowledge and investment experience.
          </p>
          <p tw="pb-2 text-sm">
            Bidda sp. z o.o. do not encourage nor suggest investing in BIDDA
            tokens. Any acquisition or disposal of BIDDA tokens is a result of a
            conscious investor’s decision.
          </p>
          <p tw="pb-2 text-sm">
            Investment in BIDDA tokens is not covered by the deposit guarantee
            schemes established in accordance with Directive 2014/49/EU of the
            European Parliament and of the Council nor is covered by the
            investor compensation schemes established in accordance with
            Directive 97/9/EC of the European Parliament and of the Council.
          </p>
        </p>
      </Container>
      <div tw="bg-dark mt-20 px-5">
        <Footer />
      </div>
    </Layout>
  );
}

export default GeneralRisk;
